import * as React from "react"
import { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useForm } from "react-hook-form"

import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const CheckoutPage = () => {
  const { register, handleSubmit, getValues } = useForm()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [quantity, setQuantity] = React.useState(1)
  const [price, setPrice] = useState(12)
  const [airtableData, setAirtableData] = useState()

  const onSubmit = async data => {
    data["Delivery date"] = [data["Delivery date"]]
    setLoading(true)
    const mappedData = {
      fields: {
        ...data,
        Amount: parseInt(quantity),
      },
    }
    const result = await axios({
      url: "https://api.airtable.com/v0/appCHj5Fhu9pAaqIL/Orders",
      method: "post",
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
        "Content-Type": "application/json",
      },
      data: mappedData,
    })

    if (result.status === 200) {
      setLoading(false)
      setSuccess(true)
      const recordId = getValues("Delivery date")
      const record = airtableData.records.find(record => record.id === recordId)
      const deliveryDate = new Date(record.fields["Time"]).toDateString()
      navigate("/success", {
        state: {
          deliveryResult: {
            ...mappedData.fields,
            "Delivery date": deliveryDate,
          },
        },
      })
    } else {
      window.alert(
        "Something went wrong while placing your order. Please try again later!"
      )
    }
  }

  useEffect(() => {
    setPrice((12 * quantity).toFixed(2))
  }, [quantity])

  useEffect(() => {
    async function fetchData() {
      const result = await axios({
        url:
          "https://api.airtable.com/v0/appCHj5Fhu9pAaqIL/Availability?maxRecords=4&view=Grid%20view",
        method: "get",
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
          "Content-Type": "application/json",
        },
      })
      setAirtableData(result.data)
    }
    fetchData()
  }, [])

  return (
    <Layout className>
      <h1>
        <span role="img" aria-label="calendar">
          📆
        </span>{" "}
        Order a Bento Box
      </h1>
      <SEO title="Order Page | Bento Box" />

      <div className="flex flex-col p-2 text-left rounded lg:p-8">
        <div className="h-full grid-cols-3 gap-8 lg:grid">
          <div className="flex flex-col col-span-2 p-4 mb-8 bg-white rounded lg:p-8 lg:mb-0">
            <StaticImage
              className="self-center rounded"
              src="../images/index_images/IMG_20210414_110124.jpg"
              width={768}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Bento Box"
            />
            <h2 className="mt-2 mb-0 text-light-blue-800">What's on the menu?</h2>
            <p className="mt-4">
              {airtableData && airtableData.records[0].fields["Menu"]}
            </p>
          </div>
          <div className="flex flex-col h-full px-2 py-4 bg-white rounded">
            <h2 className="text-center">Order form</h2>
            <form
              className="flex flex-col h-full p-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col flex-grow">
                {/* <label htmlFor="Name">Name</label> */}
                <input
                  className="p-2 mb-4 border rounded border-blue-gray-100"
                  type="text"
                  placeholder="Name"
                  name="Name"
                  ref={register({ required: true, maxLength: 80 })}
                />

                {/* <label htmlFor="Address">Address</label> */}
                <input
                  className="px-2 py-1 mb-4 border rounded border-blue-gray-100"
                  type="text"
                  placeholder="Address"
                  name="Address"
                  ref={register({ required: true, maxLength: 80 })}
                />

                {/* <label htmlFor="Email">Email</label> */}
                <input
                  className="p-2 mb-4 border rounded border-blue-gray-100"
                  type="text"
                  placeholder="Email"
                  name="Email"
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                />

                {/* <label htmlFor="Phone number">Phone number</label> */}
                <input
                  className="p-2 mb-4 border rounded border-blue-gray-100"
                  type="tel"
                  placeholder="Phone number"
                  name="Phone number"
                  ref={register({ required: true, maxLength: 12 })}
                />

                <label className="font-semibold blue-gray-800" htmlFor="Amount">
                  Select amount
                </label>
                <select
                  className="p-2 mb-4 border rounded border-blue-gray-100"
                  onBlur={e => setQuantity(e.target.value)}
                  name="Amount"
                  ref={register({ required: true })}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(qty => (
                    <option key={qty} value={qty}>
                      {qty}
                    </option>
                  ))}
                </select>

                <label
                  className="font-semibold blue-gray-800"
                  htmlFor="Delivery Date"
                >
                  Delivery date
                </label>
                <select
                  className="p-2 mb-4 border rounded border-blue-gray-100"
                  name="Delivery date"
                  ref={register({ required: true })}
                >
                  {airtableData &&
                    airtableData.records.map(record => {
                      const recordDate = new Date(record.fields["Time"])
                      if (recordDate >= new Date()) {
                        return (
                          <option key={record.id} value={record.id}>
                            {recordDate.toDateString()}
                          </option>
                        )
                      }
                      return null;
                    })}
                </select>
                <div className="text-right text-gray-700">
                  <div>
                    {quantity}x Bento Box{quantity > 1 && "es"}: {price}
                  </div>
                  <div>Delivery: €4.00</div>
                  <div className="font-semibold">
                    Total: €{parseInt(parseInt(price) + 4).toFixed(2)}
                  </div>
                </div>
              </div>
              <input
                className="p-4 mx-auto mt-4 text-white bg-green-800 rounded cursor-pointer hover:bg-green-700"
                disabled={loading || success}
                type="submit"
                value={`${
                  loading
                    ? "Loading..."
                    : success
                    ? "✔️ Order submitted!"
                    : `Place order (total €${parseInt(
                        parseInt(price) + 4
                      ).toFixed(2)})`
                }`}
              />
            </form>
          </div>
        </div>
      </div>
      <Link className="font-semibold text-gray-900 hover:underline" to="/">
        ← Back
      </Link>
    </Layout>
  )
}

export default CheckoutPage
